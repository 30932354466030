.scorecard-background {
  width: 100%;
}

.safety-info-container {
  max-width: 800px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  margin: auto;
  padding-top: 10px;
  box-sizing: border-box;
  word-wrap: break-word;
  overflow-x: hidden;
}

.taxonomy-label {
  display: inline-block;
  margin-right: 10px;
}

.taxonomy-expand-icon {
  font-size: 24px;
  color: #61dafb;
}

.taxonomy-item .taxonomy-item {
  margin-left: 20px;
  border-left: 2px solid #484e56;
  padding-left: 10px;
}

.text-container {
  margin-left: 30px;
  padding-left: 10px;
  margin-right: 30px;
}

.taxonomy-container {
  margin-top: 20px;
  color: #000;
  border-radius: 5px;
  padding: 15px;
  width: 90%;

  max-width: 600px;
}

.taxonomy-item {
  cursor: pointer;
  padding: 10px 0;
}

.label-slider-container {
  margin-left: 30px;
  padding-left: 10px;
  margin-right: 30px;
}

.dropdown-toggle {
  cursor: pointer;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
}

.dropdown-list {
  position: absolute;
  z-index: 1;
  list-style-type: none;
  padding: 0;
  margin: 0;
  background-color: #ffffff;
  border: 1px solid #ddd;
  width: 100%;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

.dropdown-item {
  cursor: default;
  border-bottom: 1px solid #eee;
}

.dropdown-item:last-child {
  border-bottom: none;
}

.dropdown-item:hover {
  background-color: #f0f0f0;
}

.dropdown-header {
  cursor: pointer;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dropdown-content {
  border-left: 2px solid #f0f0f0;
}

.no-style-link {
  text-decoration: none;
  /* Remove underline */
  color: inherit;
  /* Inherit the color from the parent element */
}

.sentence {
  margin-top: 5px;
}

.collapsible-header {
  font-size: medium;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.collapsible-header span {
  display: inline-block;
}

.dropdown-symbol {
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.collapsible-header:not(.main-header) {
  font-size: medium;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
}

.collapsible-content {
}

.collapsible-item {
  list-style-type: none;
}

.collapsible-content li {
  margin-bottom: 15px;
}

.dropdown-link {
  text-decoration: none;
  color: #607d8b;
}

.collapsible-content ul {
  margin-top: 10px;
  padding: 10px;
  margin-left: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.video-title {
  padding: 0 10px;
  font-size: 18px;
  margin: 10px 0 0 0;
  color:#263228;
}

.creator-name {
  padding-right: 15px;
  padding-left: 15px;
  color: #263238;
}

.video-container {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 10px;
}

.embed-wrapper {
  width: 100% !important;
  display: flex !important;
  justify-content: center !important;
  overflow: hidden !important;
}

.twitter-embed-container {
  margin: auto 15px auto 15px;
  width: 400px;
}

.audio-embed-wrapper {
  width: 100% !important;
  display: flex !important;
  justify-content: center !important;
  overflow: hidden !important;
}

.audio-embed-container {
  display: flex;
  justify-content: center;
  margin: 4px 4px;
  width: 100%;
}

.audio-player {
  width: 100%;
}

.video-responsive {
  position: relative;
  overflow: hidden;
  width: 100%;
  max-height: 750px;
  max-width: 800px;

  aspect-ratio: 16 /9;
  background: #000;
}

.video-responsive iframe {
  position: relative;
  width: 100%;
  height: 100%;
}

.tiktok-responsive {
  position: relative;
  /* overflow: hidden; */
  /* width: auto; */
  height: 70%;
  /* max-width: 800px; */

  aspect-ratio: 9/22;
  background: #000;
}

.tiktok-responsive iframe {
  position: relative;
  width: 100%;
  height: 100%;
}

.gauge-chart-container {
  width: 100%;
  height: 100%;
  object-fit: scale-down;
}
